@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;600;700&display=swap");

@keyframes blink {
  50% { opacity: 0; }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

body {
  font-family: "Fira Code", monospace;
  @apply bg-dracula-background text-dracula-foreground;
}

.cursor {
  display: inline-block;
  width: 10px;
  height: 20px;
  background-color: #FF79C6;
  margin-left: 4px;
  animation: blink 1s infinite;
}

.spinner {
  display: block;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
  margin: 0 auto;
}

.textHighlight {
  @apply bg-dracula-foreground text-dracula-background;
  padding: 1px 4px;
  border-radius: 4px;
}